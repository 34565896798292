import { LicenseServerRepository } from './repositories/LicenseServerRepository';
import { LibApiRepository } from './repositories/LibApiRepository';
import { IRepository, ISearchQueryPayload, IEntity, IListPayload } from './types';

interface LibDictionary {
  'license-server': LicenseServerRepository
  'lib-api': LibApiRepository
}

type TRepoLibKey = keyof LibDictionary

type TRepoLib = {
  name: TRepoLibKey;
};

const libDictionary: LibDictionary = {
  'license-server': new LicenseServerRepository(),
  'lib-api': new LibApiRepository()
};

export const repositoryGetter = (libName: TRepoLibKey = 'lib-api') => (
  libDictionary[libName]
);

export type {
  IRepository,
  TRepoLibKey,
  TRepoLib,
  ISearchQueryPayload,
  IEntity,
  IListPayload
}
